<template>
  <component
    :class="$style.root"
    :is="component"
    v-animated-underline-mouse-events
    :disabled="disabled ? '' : null"
  >
    <slot name="before" />
    <span
      class="animated-underline"
      :data-inverted="inverted ? '' : null"
      :data-active="active ? '' : null"
      :data-ignore="ignore ? '' : null"
    >
      <slot name="default" />
    </span>
    <slot name="after" />
  </component>
</template>

<script lang="ts" setup>
import i18nLink from '~/components/i18n/link.vue'

const props = defineProps({
  component: {
    type: [String, Object]
  },
  inverted: {
    type: Boolean,
    required: false,
    default: false
  },
  active: {
    type: Boolean,
    required: false,
    default: false
  },
  ignore: {
    type: Boolean,
    required: false,
    default: false
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false
  }
})

const component = props?.component || i18nLink
</script>

<style module>
.root {
  composes: reset-link from global;
}

.root:focus {
  outline: none;
}
</style>
